<template>
    <div class="nav-item" :class="{ 'nav-item__active': route.path === props.path }" @click="navigate">
        <div class="flex gap-3 items-center">
            <slot />
            <span class="text-style-link">{{ props.title }}</span>
        </div>
        <lock-icon v-if="isProtected && !auth.loggedIn && !trustPage" />
    </div>
</template>

<script setup>
import LockIcon from '~/assets/icons/lock.svg';

const route = useRoute();
const layout_store = useLayoutStore();
const auth = useAuthStore();
const config = useRuntimeConfig();

const props = defineProps({
    isProtected: Boolean,
    title: String,
    path: String,
    trustPage: Boolean,
});

const navigate = () => {
    if (props.trustPage) {
        return (window.location.href = config.public.pb.trustHost + props.path);
    }

    if (!auth.loggedIn && props.isProtected) {
        return usePbRouter('/auth/sign-in');
    }

    usePbRouter(props.path);
    layout_store.closeMobileNav();
};
</script>

<style lang="scss" scoped>
.nav-item {
    @apply flex py-3 items-center gap-3 justify-between cursor-pointer px-6;
    transition: all ease-in-out 0.1s;

    &:hover {
        @apply bg-blue-300;
    }

    span {
        @apply text-white-700 leading-none;
    }
}

.nav-item__active {
    @apply bg-gradient.blue-transparent border-l-2 border-l-blue-700;
}
</style>
