<template>
    <!-- <div class="search-button-visible" :class="{ 'search-button-hidden': !closed }" @click="toggle">
        <search-icon />
    </div>
    <div
        class="search-field-hidden"
        :class="{
            'search-field-visible': !closed,
            'search-field-focused': isTyping,
        }"
        @clickOutside="closed = true"
    >
        <search-icon :class="{ hidden: closed }" class="w-2" />

        <input
            type="text"
            :class="{ hidden: closed }"
            class="flex-1 bg-[transparent] outline-none h-full px-3 transition-none"
            placeholder="Search anything..."
            @focus="isTyping = true"
            @blur="isTyping = false"
            @click="toggleResults"
        />

        <div class="w-12 flex justify-center">
            <close-circle-icon class="close-icon" @click="toggle" v-show="!closed" />
        </div>

        <PrimePopover ref="showResults">
            <div class="search-results-container">
                <label>search results</label>

                <div class="result-item p-3 bg-blue-200 rounded-md flex gap-3 items-center justify-between mt-3">
                    <div class="bg-slate-400 w-10 h-10 rounded-full"></div>

                    <div class="flex flex-col flex-1">
                        <span class="text-style-link">Microsoft</span>
                        <label>https://www.microsoft.com</label>
                    </div>

                    <arrow-right-icon class="fill-blue-700" />
                </div>

                <div class="result-item p-3 rounded-md flex gap-3 items-center justify-between">
                    <div class="bg-slate-400 w-10 h-10 rounded-full"></div>

                    <div class="flex flex-col flex-1">
                        <span class="text-style-link">Microsoft</span>
                        <label>https://www.microsoft.com</label>
                    </div>

                    <arrow-right-icon class="fill-blue-700" />
                </div>

                <div class="result-item p-3 rounded-md flex gap-3 items-center justify-between">
                    <div class="bg-slate-400 w-10 h-10 rounded-full"></div>

                    <div class="flex flex-col flex-1">
                        <span class="text-style-link">Microsoft</span>
                        <label>https://www.microsoft.com</label>
                    </div>

                    <arrow-right-icon class="fill-blue-700" />
                </div>
            </div>
        </PrimePopover>
    </div> -->

    <form action="" class="search">
        <input class="search__input" type="search" placeholder="Search" id="searchInput" @input="toggle" />

        <div class="search__icon-container">
            <label for="searchInput" class="search__label" aria-label="Search">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path
                        fill-rule="evenodd"
                        d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                        clip-rule="evenodd"
                    />
                </svg>
            </label>

            <button class="search__submit" aria-label="Search">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path
                        fill-rule="evenodd"
                        d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                        clip-rule="evenodd"
                    />
                </svg>
            </button>
        </div>

        <PrimePopover ref="op">
            <div class="search-results-container">
                <label>search results</label>

                <div class="result-item p-3 bg-blue-200 rounded-md flex gap-3 items-center justify-between mt-3">
                    <div class="bg-slate-400 w-10 h-10 rounded-full"></div>

                    <div class="flex flex-col flex-1">
                        <span class="text-style-link">Microsoft</span>
                        <label>https://www.microsoft.com</label>
                    </div>

                    <arrow-right-icon class="fill-blue-700" />
                </div>

                <div class="result-item p-3 rounded-md flex gap-3 items-center justify-between">
                    <div class="bg-slate-400 w-10 h-10 rounded-full"></div>

                    <div class="flex flex-col flex-1">
                        <span class="text-style-link">Microsoft</span>
                        <label>https://www.microsoft.com</label>
                    </div>

                    <arrow-right-icon class="fill-blue-700" />
                </div>

                <div class="result-item p-3 rounded-md flex gap-3 items-center justify-between">
                    <div class="bg-slate-400 w-10 h-10 rounded-full"></div>

                    <div class="flex flex-col flex-1">
                        <span class="text-style-link">Microsoft</span>
                        <label>https://www.microsoft.com</label>
                    </div>

                    <arrow-right-icon class="fill-blue-700" />
                </div>
            </div>
        </PrimePopover>
    </form>
</template>

<script setup>
//icons
import SearchIcon from '@/assets/icons/search.svg';
import CloseCircleIcon from '@/assets/icons/close-circle.svg';
import ArrowRightIcon from '@/assets/icons/arrow-right.svg';

//comps
import PrimePopover from 'primevue/popover';

const op = ref();

const toggle = (event) => {
    op.value.show(event);
};
</script>

<style lang="scss" scoped>
.search-button-visible {
    @apply bg-slate-100 h-8 md:flex justify-center items-center
        rounded-full cursor-pointer opacity-100 select-none w-12;
    transition: width 0.3s ease;
}

.search-button-hidden {
    position: absolute;
    @apply opacity-0 w-80;
}

.search-field-hidden {
    position: absolute;
    transition: width 0.3s ease;
    @apply w-0;
}

.search-field-visible {
    position: relative;
    @apply w-auto h-8 pl-3 focus:outline-none flex justify-between items-center bg-slate-100 rounded-full select-none;
}

.search-field-focused {
    box-shadow: 0px 2px 0px 0px var(--slate-200, rgba(2, 6, 23, 0.08));
    @apply bg-white-700;
}

//icons

.close-icon {
    @apply cursor-pointer fill-slate-700;
}

.search-results-container {
    @apply bg-white-700 p-4 rounded-lg relative top-7 w-80 -left-8;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16);
    animation: alternate 0.3s ease-in-out fadeIn;
}

// New Seach Bar

:root {
    --color-background: #020617;
    --color-foreground: #cbd5e1;
}

.search {
    --easing: cubic-bezier(0.4, 0, 0.2, 1);
    --font-size: 14px;
    --color: #64748b;
    --color-highlight: var(--color-foreground);
    --transition-time-icon: 0.2s;
    --transition-time-input: 0.3s 0.25s;

    &:not(:focus-within) {
        --transition-time-input: 0.2s 0s;
    }

    @media (prefers-reduced-motion: reduce) {
        --transition-time-icon: 0s !important;
        --transition-time-input: 0s !important;
    }

    $root: &;

    border: none;
    display: flex;
    transition: padding var(--transition-time-input) var(--easing);

    &__input {
        background: transparent;
        border: none;
        color: var(--color-highlight);
        font-size: var(--font-size);
        opacity: 0;
        outline: none;
        padding: 0;
        transition: width var(--transition-time-input) var(--easing), padding var(--transition-time-input) var(--easing),
            opacity var(--transition-time-input) linear;
        width: 0;

        &::placeholder {
            color: var(--color);
            opacity: 0.75;
        }
    }

    &__icon-container {
        height: calc(var(--font-size) + 0.5rem);
        position: relative;
        width: calc(var(--font-size) + 0.5rem);
    }

    &__label,
    &__submit {
        color: var(--color);
        cursor: pointer;
        display: block;
        height: 100%;
        padding: 0;
        position: absolute;
        width: 100%;

        &:hover,
        &:focus,
        &:active {
            color: var(--color-highlight);
        }
    }

    &__label {
        transition: transform var(--transition-time-icon) var(--easing), color 0.1s;
    }

    &__submit {
        background: none;
        border-radius: 50%;
        border: none;
        box-shadow: 0 0 0 4px inset transparent;
        display: none;
        outline: none;
        transition: color 0.1s, box-shadow 0.1s;

        svg {
            transform: scale(0.8);
        }

        &:focus {
            box-shadow: 0 0 0 4px inset var(--color-highlight);
        }
    }

    &:focus-within {
        border-color: var(--color);

        #{$root} {
            &__input {
                opacity: 1;
                padding: 0 1rem 0 2rem;
                width: calc(var(--font-size) * 12);
            }

            &__label {
                transform: scale(0.8);
            }

            &__submit {
                animation: unhide var(--transition-time-icon) steps(1, end);
                display: block;
            }
        }
    }
}

@keyframes unhide {
    from {
        height: 0;
        opacity: 0;
    }

    to {
        height: auto;
        opacity: 1;
    }
}
</style>
