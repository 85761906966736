<template>
    <header class="app-header" :class="{'no-border': isManageAccount }">
        <div class="flex gap-9 justify-center items-center">
            <mobile-navigation />
            <h1 class="page-name">{{ route.name }}</h1>
        </div>
        <div class="flex gap-4 items-center">
            <search-bar />
            <extension-popup />
            <protection-badge v-if="personalStore.person" />
            <user-popup v-if="personalStore.person" />
        </div>
    </header>
</template>

<script setup>
//comps
import UserPopup from '@/components/layouts/AppHeader/UserPopup.vue';
import ProtectionBadge from '@/components/layouts/AppHeader/ProtectionBadge.vue';
import ExtensionPopup from './AppHeader/ExtensionPopup.vue';
import SearchBar from '@/components/layouts/AppHeader/SearchBar.vue';
import MobileNavigation from '@/components/layouts/MobileNavigation.vue';

const route = useRoute();
const personalStore = usePersonalStore();

const isManageAccount = computed(() => route.name === 'Manage Account');
</script>

<style lang="scss" scoped>
.app-header {
    @apply bg-white-700 h-[var(--size-header-height)] px-9 flex items-center justify-between border-b border-slate-300 shadow-sm;
}

.page-name {
    text-overflow: ellipsis;

    @apply whitespace-nowrap overflow-hidden w-36 sm:w-auto;
}

.no-border {
    @apply border-b-0 shadow-none;
}
</style>
