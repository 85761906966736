<template>
    <DynamicDialog />

    <div>
        <div class="hidden lg:block"><personal-navigation /></div>

        <div class="layout-container">
            <app-header />

            <slot />

            <app-footer :has-double-sidebar="route.meta.pageGroup == 'trust' && auth_store.loggedIn" />
        </div>
    </div>
</template>

<script setup>
import AppFooter from '~/components/layouts/AppFooter.vue';
import PersonalNavigation from '~/components/layouts/PersonalNavigation.vue';
import AppHeader from '~/components/layouts/AppHeader.vue';
import DynamicDialog from 'primevue/dynamicdialog';

const route = useRoute();
const auth_store = useAuthStore();
</script>

<style lang="scss" scoped>
.layout-container {
    @apply lg:ml-[280px] flex flex-col min-h-screen bg-white-700;
}
</style>
