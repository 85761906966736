export const useLayoutStore = defineStore('layout', () => {
    const isMobileNavOpen = ref(false);

    function toggleMobileNav() {
        isMobileNavOpen.value = !isMobileNavOpen.value;
    }

    function closeMobileNav() {
        isMobileNavOpen.value = false;
    }

    return {
        isMobileNavOpen,
        toggleMobileNav,
        closeMobileNav,
    };
});
