<template>
    <burger-menu-icon class="lg:hidden" @click="store.toggleMobileNav" />

    <prime-drawer v-model:visible="store.isMobileNavOpen" :showCloseIcon="false">
        <div class="drawer">
            <p @click="store.toggleMobileNav" class="w-screen bg-slate-700">close me</p>
            <personal-navigation />
        </div>
    </prime-drawer>
</template>

<script setup>
import PrimeDrawer from 'primevue/drawer';
import PersonalNavigation from './PersonalNavigation.vue';
import BurgerMenuIcon from '@/assets/icons/burger-menu.svg';

const store = useLayoutStore();
</script>

<style lang="scss" scoped>
.drawer {
    @apply h-screen w-screen;
}
</style>
