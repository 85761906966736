<template>
    <div
        class="protection-badge"
        :class="{
            'protected-user': person.pro,
            'unprotected-user': !person.pro,
        }"
        v-if="person"
        @click="toggle"
    >
        <locked-icon v-if="person.pro" />

        <span v-if="person.pro" class="hidden sm:flex">Protected</span>
        <span v-else class="hidden sm:flex" @click="useDynamicDialog('personal:upsell')">Upgrade</span>
    </div>
</template>

<script setup>
//icons
import LockedIcon from '@/assets/icons/locked-icon.svg';

//comps
import PbButton from '~/components/shared/PbButton.vue';

//store
const { person } = usePersonalStore();
</script>

<style lang="scss" scoped>
.protection-badge {
    @apply flex items-center justify-center gap-2 h-8 px-3 rounded-3xl cursor-pointer select-none;

    span {
        @apply text-red-700 font-inter font-semibold text-sm;
    }
}

.protected-user {
    @apply bg-green-200;

    span {
        @apply text-green-700;
    }
}

.unprotected-user {
    @apply relative z-0 overflow-hidden p-4;

    span {
        background: linear-gradient(to right, #2563eb 20%, #d946ef 40%, #d946ef 60%, #2563eb 80%);
        background-size: 200% auto;
        
        color: #000;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        
        animation: shine 2s linear infinite;
        @keyframes shine {
            to {
                background-position: 200% center;
            }
        }
    }
	
	&::before {
		content: '';
		position: absolute;
		z-index: -2;
		left: -50%;
		top: -100%;
		width: 200%;
		height: 400%;
		background-color: #2563eb;
		background-repeat: no-repeat;
		background-size: 100% 100%, 100% 100%;
		background-position: 0 0, 100% 0, 100% 100%, 0 100%;
		background-image: linear-gradient(#2563eb, #d946ef), linear-gradient(#d946ef, #2563eb);
		animation: rotate 4s linear infinite;
	}
	
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 1px;
		top: 1px;
		width: calc(100% - 2px);
		height: calc(100% - 2px);
		background: #eff6ff;
		border-radius: 40px;
	}
}

.protection-popup-container {
    @apply bg-white-700 p-5 rounded-lg relative top-7;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.16);
    animation: alternate 0.3s ease-in-out slideIn;
}

.divider {
    @apply bg-slate-200 mx-3 my-2 h-px;
}

@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}
</style>
