<template>
    <div class="cursor-pointer hidden sm:flex" @click="toggle">
        <chrome-logo />

        <PrimePopover ref="isOpen">
            <div class="ext-popup-container">
                <div class="flex gap-3 pt-4 pr-3 pb-2 pl-4">
                    <chrome-logo />

                    <div class="flex flex-col">
                        <span class="text-style-link">Try Our Chrome Extension!</span>
                        <label class="text-slate-700">Free with Privacy Bee Pro</label>
                    </div>
                </div>

                <div class="divider"></div>

                <div class="px-4 py-2 max-w-72">
                    <label class="text-slate-600">
                        Take control of your privacy as you browse the web. Easily see your company-level preferences on every
                        site you visit, then quickly make adjustments as needed.
                    </label>
                </div>

                <div class="p-4">
                    <pb-button
                        class="w-full hover group"
                        type="secondary"
                        label="Download Extension"
                        pt:label="!text-blue-700 group-hover:!text-white-700"
                        >Add to Chrome</pb-button
                    >
                </div>
            </div>
        </PrimePopover>
    </div>
</template>

<script setup>
import ChromeLogo from '@/assets/icons/chrome-logo.svg';

import PrimePopover from 'primevue/popover';
import PbButton from '@/components/shared/PbButton.vue';

const isOpen = ref();

const toggle = (event) => {
    isOpen.value.toggle(event);
};

const personalStore = usePersonalStore();
</script>

<style lang="scss" scoped>
.ext-popup-container {
    @apply bg-white-700 p-2 rounded-lg relative top-7 border border-slate-300;
    animation: alternate 0.3s ease-in-out slideIn;
}

.divider {
    @apply bg-slate-200 mx-3 my-2 h-px;
}
</style>
